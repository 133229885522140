.headerPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 30px 24px 30px 24px;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerIcons {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 120px;
  height: auto;
  cursor: pointer;
  position: absolute;
  top: -15px;
  z-index: 6;
}

.icons {
  display: flex;
  align-items: center;
}

.icons img {
  margin-right: 1rem;
  cursor: pointer;
}

.Header {
  display: flex;
  justify-content: space-between;
  height: 90px;
  font-size: 30px;
  /* box-shadow: 5px 5px 20px 5px; */
  background-color: inherit;
  align-items: center;
}

.Header div {
  /* border-radius: 8px; */
  /* text-shadow: 0 0 3px #111010, 0 0 5px #0000FF; */
  /* height: 40px;  */
  /* box-shadow: 1px 1px 5px 1px;  */

  padding: 3px;
  background-color: inherit;
}

.no-decoration {
  text-decoration: none;
}
/* .ThemeMainDiv {
  position: absolute;
  margin-bottom: 7px;
  left: 240px;
}

.lightMode {
  background: url(../Icons/Toogle.png) no-repeat;
  background-size: cover;
  width: 30px;
  height: 24px;
  cursor: pointer;
}

.darkMode {
  background: url(../Icons/toggle-on.png) no-repeat;
  background-size: cover;
  width: 30px;
  height: 24px;
  cursor: pointer;
  /* margin-bottom: 5px; */

.invisible {
  display: none;
}
.lightMode:hover,
.darkMode:hover,
.home:hover,
.logo:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.2s ease;
}
/* .darkLightStyles {
  width: 30px;
  height: 29px;
  position: relative;
} */
/* 
@media screen and (min-width: 400px) {
  .ThemeMainDiv {
    left: 73%;
  }
}
@media screen and (min-width: 500px) {
  .ThemeMainDiv {
    left: 78%;
  }
}
@media screen and (min-width: 600px) {
  .ThemeMainDiv {
    left: 82%;
  }
} */
@media screen and (min-width: 375px) {
  .logo {
    width: 160px;
    top: -25px;
  }
}
@media screen and (max-height: 500px) {
  .logo {
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  /* .ThemeMainDiv {
    left: 90%;
  } */
  .logo {
    left: 200%;
  }
}
