* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  text-decoration: none;
  transition-duration: 0.5s;
  scrollbar-width: thin; /* "auto" or "thin" for Firefox */
  scrollbar-color: transparent transparent;
}
.homeArrow {
  align-self: center;
  position: absolute;
  left: 50%;
  top: 90%;
}
.homeArrow {
  /* align-items: center;
  align-self: center; */
  background: transparent;
  /* height: 2.25rem;
  width: 2rem; */
  /* border-radius: 60px; */
  border: none;
  cursor: pointer;
  animation: arrowAnimation 0.5s ease-in-out infinite alternate;
  z-index: 2;
}

#arrow_button img {
  width: 100%;
}

@keyframes arrowAnimation {
  from {
    transform: translateY(0.313rem);
  }
  to {
    transform: translateY(-0.625rem);
  }
}

:root {
  --white-to-black: white;
  --home_page-background: rgb(0, 0, 0, 0.7);
  --navbar_background: rgb(0, 0, 0, 0.9);
  --scrollbar-thumb-bg-color: rgba(229, 229, 72, 1);
  --transparent_text_border: rgba(102, 102, 102, 1);
  --black-to-transparent: black;
  --mobile-home_page-background: rgb(0, 0, 0, 1);
}

/* light mode */

.light-mode {
  --black-to-white: white;
  --white-to-black: black;
  --home_page-background: rgba(212, 209, 209, 0.7);
  --navbar_background: rgba(199, 197, 197, 0.9);
  --scrollbar-thumb-bg-color: black;
  --transparent_text_border: black;
  --black-to-transparent: transparent;
}

a,
li,
ul {
  text-decoration: none;
  list-style-type: none;
}
