.footerPage {
  background-color: #0000ff;
  padding: 32px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  min-height: 100vh;
  justify-content: space-between;
}
.footerPage {
  padding-top: 110px;
}
.footerText {
  gap: 32px;
}
.footerText h1,
.footerText h2 {
  font-family: Supreme;
  font-size: 40px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0px;
  text-align: left;
  color: transparent;
  -webkit-text-stroke: 0.188rem rgba(0, 0, 0, 1);
}
.footerText span {
  color: #000000;
  text-decoration: line-through 2px;
  text-decoration-line: line-through;
  text-decoration-color: #000000;
}
.footerButton {
  width: 108px;
  height: 108px;
  background-color: #ffffff;
  border: none;
  border-radius: 100%;
  font-family: Supreme;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.016em;
  text-align: center;
}
.footerButton:hover {
  background-color: #000000;
  color: white;
  cursor: pointer;
}
.footerSerAndSoc {
  display: flex;
  gap: 68px;
}

.footerMenu {
  font-family: Supreme;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #000000;
}

.footerSocList {
  font-family: Supreme;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: left;
  color: #000000;
}
.instagram,
.facebook {
  display: flex;
  gap: 10px;
}

.lastFooter,
.footerMenu,
.footerSocList,
.mailPhone,
.footerText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerMenu {
  gap: 32px;
}
.footerSocList {
  justify-content: center;
}
.lastFooter {
  gap: 16px;
  margin-top: 50px;
}

.lastFooter h2 {
  font-family: Supreme;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 2px;
  text-align: left;
  color: #000000;
}
.lastFooter h2:hover {
  color: white;
}
.lastFooter a {
  font-family: Supreme;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: left;
}
.lastFooter a:hover,
.footerMenu ul li a:hover,
.instagram a:hover,
.facebook a:hover {
  cursor: pointer;
  color: white;
}
.flexReverse {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .footerText h1,
  .footerText h2 {
    font-size: 50px;
  }
}
@media screen and (min-width: 768px) {
  .footerPage {
    padding-left: 90px;
  }
  .footerPage {
    padding-right: 90px;
  }
  .footerText {
    flex-direction: row;
    justify-content: flex-start;
    /* max-width: 750px; */
  }
  .footerButton {
    align-self: center;
    padding: 20px;
    text-wrap: nowrap;
    text-align: center;
  }
  .flexReverse {
    flex-direction: row-reverse;
  }
  .lastFooter {
    justify-content: center;
  }
  .flexReverse {
    gap: 90px;
  }
  .footerSerAndSoc {
    gap: 90px;
  }
}
@media screen and (min-width: 1100px) {
  .footerPage {
    padding-left: 120px;
  }

  .flexReverse {
    gap: 128px;
  }
  .footerSerAndSoc {
    gap: 128px;
  }
  .footerText {
    gap: 8px;
  }
  .footerText h1 {
    font-size: 88px;
    font-weight: 700;
    line-height: 88px;
    letter-spacing: 0px;
    text-align: left;
  }
  .footerButton {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.016em;
    text-align: center;
    margin-right: 80px;
  }
  .lastFooter h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: left;
  }
  .mailPhone a {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: left;
  }
  .footerMenu ul a li {
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .footerSocList a {
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
  }
}
