.aboutPage {
  background-color: #000000;
  padding: 32px 24px 32px 24px;
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.aboutPage h3 {
  color: transparent;
  -webkit-text-stroke: 0.5px #666666;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  font-size: 30px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}
.aboutPage span {
  color: #0000ff;
  text-decoration: line-through 1px;
  text-decoration-line: line-through;
  text-decoration-color: #0000ff;
  -webkit-text-stroke: transparent;
}
.aboutBtn {
  width: 109px;
  height: 48px;
  border-radius: 80px;
  border: 1px solid white;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.016em;
  text-align: center;
  background-color: transparent;
  font-family: "BPGNinoMtavruliNormal", sans-serif;
}
.aboutBtn:hover {
  box-shadow: 2px 3px 3px #0000ff;
}
@media screen and (min-width: 768px) {
  .aboutPage h3 {
    font-size: 36px;
  }
}

@media screen and (min-width: 768px) {
  .aboutPage h3 {
    padding-left: 100px;
  }
  .aboutBtn {
    margin-left: 90px;
  }
}

@media screen and (min-width: 1400px) {
  .aboutPage h3 {
    font-size: 88px;
    font-weight: 700;
    line-height: 88px;
    letter-spacing: 0px;
    text-align: left;
    /* padding-left: 180px; */
    align-items: center;
    margin-bottom: 100px;
  }
  /* .aboutPage h3.en {
    font-size: 88px;
    font-weight: 700;
    line-height: 88px;
    letter-spacing: 0px;
    text-align: left;
    
  } */
}
